import React from "react"
import { Link } from "gatsby"
import './post-link.css'

const PostLink = ({ post }) => (
  <div className="link-container">
    <Link to={post.frontmatter.path} className="link-title">
      {post.frontmatter.title}
    </Link>
    <div className="link-date">
        {post.frontmatter.date}
    </div>
    <div className="link-desc">
        <span role="img" alt="emoji">🍯</span><b>TL;DR:{` `}</b> {post.frontmatter.desc}
    </div>
    
  </div>
)

export default PostLink