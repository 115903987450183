/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

import Header from "./header"
import "./layout.css"





const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          siteName
        }
      }
    }
  `)

  return (
    <>
    <div className="layout-container">
        <Header siteTitle={data.site.siteMetadata.siteName} className="header" />
        
        <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <label className="themetoggler">
            <input
              type="checkbox"
              onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
              checked={theme === 'dark'}
              className="checkbox"
            />{' '}
            {theme==='dark'?<span>🌙夜🌙</span>:<span>☀️昼☀️</span>}
          </label>
        )}
      </ThemeToggler>

        <div className="navbar">
          <Link to="/blog/" className="text-link">BLOG</Link>
          <Link to="/update/" className="text-link">WHAT'S UP</Link>
          <Link to="/more/" className="text-link">MORE</Link>
        </div>
        <div className="maincontent">
          {children}
        </div>
    </div>
      


    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


        {/* <footer>
          © {new Date().getFullYear()}, all rights reserved to
          {` `}erin xue
        </footer> */}


      

        {/* <div id="themebtn">
                
                <button 
                  className='themebtn'
                  id="light"
                  
                >
                  <span class="emoji">☀️</span>
                </button>
                <button 
                  className='themebtn'
                  id="dark"


                >
                  <span class="emoji">🌙</span>
                </button>
                <button className='themebtn' id="sepia">
                  <span class="emoji">🌦</span>
                </button>

          </div>
           */}

      